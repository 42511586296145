import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Link,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useAuth } from "../../AuthProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      'url("https://static.vecteezy.com/system/resources/previews/011/845/213/non_2x/aesthetic-abstract-curve-striped-gradient-green-blank-frame-wallpaper-illustration-perfect-for-wallpaper-backdrop-postcard-background-banner-vector.jpg")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      justifyContent: "flex-start", // Adjust alignment for smaller screens
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: "185px", // Adjust the width of the logo as needed
    height: "160px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "130px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
      width: "90%", // Take most of the width on small screens
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "5px",
    },
    "& .MuiInputBase-input": {
      color: "black",
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#2ED0A5",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2, 0, 1),
    },
  },
  link: {
    marginTop: theme.spacing(2),
    color: "#FFFFFF",
    textAlign: "center",
    display: "block",
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    companyEmailId: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { setCompanyEmailId } = useAuth(); // Get the function to set companyEmailId in global state

  const validate = () => {
    const newErrors = {};
    let isValid = true;

    if (!values.companyEmailId) {
      newErrors.companyEmailId = "Email is required";
      isValid = false;
    }

    if (!values.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    
    setLoading(true);
    setError(""); // Clear previous errors
    
    try {
      
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      
      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/verify/org-verify`, {
        method: "POST",
        body: formData,
      });
      
      const responseData = await response.json(); // Parse JSON response
  
      // Log the response to check its content
      console.log("Response Status:", response.status);
      console.log("Response Data:", responseData);
  
      if (response.ok) {
        console.log("Successful response data:", responseData);
        swal("Welcome!", "Logged In Successfully!", "success");
        setCompanyEmailId(responseData.companyEmailId); // Set companyEmailId in global state
        navigate("/dashboard");
      } else {
        
        swal("Error!", "Failed to Log In: " + responseData.message || "Unknown error", "error");
      }
    } catch (error) {
      console.error("Caught error:", error); // Log the error for debugging
      swal("Server issue!", "Check server connection", "error");
    } finally {
      setLoading(false);
    }
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateAccount = () => {
    navigate("/signup"); // Navigate to Signup page
  };

  return (
    <div className={classes.root}>
      <Box className={classes.logoContainer}>
        <img
          src="/assets/hrbplogo.png"
          alt="Company Logo"
          className={classes.logo}
        />
        <Typography variant="h5" style={{ color: "#801CA7", fontWeight: "bold" }}>
          HRBP PAYROLL
        </Typography>
      </Box>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Typography variant="h4" align="center" style={{ color: "white" }}>
          Sign In
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            className={classes.textField}
            variant="filled"
            fullWidth
            id="companyEmailId"
            label="Email"
            type="email"
            name="companyEmailId"
            autoComplete="companyEmailId"
            autoFocus
            value={values.companyEmailId}
            onChange={handleChange}
            error={!!error.companyEmailId}
            helperText={error.companyEmailId}
          />
          <TextField
            className={classes.textField}
            variant="filled"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
            error={!!error.password}
            helperText={error.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
            disabled={loading}
          >
            {loading ? "Log In..." : "Log In"}
          </Button>
          <Typography  align="center" style={{ color: "white" }}>
          Not registered yet? 
          <Link onClick={handleCreateAccount} style={{ color: "#ffff",fontWeight:"bold",cursor:'pointer' }}> 
           Create an account
          </Link> 
        </Typography>

          {/* <Link
            variant="body2"
            className={classes.link}
            onClick={handleCreateAccount}
            style={{ cursor:'pointer'}}
          >
            Not registered yet? Create an account
          </Link> */}
        </form>
      </Container>
    </div>
  );
};

export default SignIn;
