import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import { styled, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";




const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

const SignedInSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const SignedInTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1.25rem",
}));

const EmployeeAttendance = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [attendance, setAttendance] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    color: "white",
    fontSize: "17px",
    textAlign: "center",
    fontWeight: "bold",
    border: "0.1rem solid black",
  }));
  
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();


  //new addded code
  useEffect(() => {
    const fetchAllAttendance = async () => {
      try {
        setLoader(true); // Show loader
        const response = await fetch(
          `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}`
        );
        const data = await response.json();
  
        const todayAttendace = data.filter(
          (employee) => employee.date === todayDate
        );
        const employeesWithImages = todayAttendace.map((employee) => ({
          ...employee,
          checkInPic: `data:image/jpeg;base64,${employee.checkInPic}`,
        }));
  
        // Sort employeesWithImages so newly added rows appear on top
        const sortedAttendance = employeesWithImages.reverse(); 
  
        setAttendance(sortedAttendance);
      } catch (error) {
        console.error("Failed to fetch attendance", error);
      } finally {
        setLoader(false); // Hide loader
      }
    };
  
    fetchAllAttendance();
  }, [todayDate]);
  
//for punch in location
  const truncateString = (str, num) => {
    if (typeof str !== "string") {
      return "Punch In Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  //for punch out location
  const truncateString1 = (str, num) => {
    if (typeof str !== "string") {
      return "Punch Out Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  
  
  
  const formatTimeFromArray = (timeArray) => {
    if (Array.isArray(timeArray) && timeArray.length >= 2) {
      const hours = String(timeArray[0]).padStart(2, "0");
      const minutes = String(timeArray[1]).padStart(2, "0");
      return` ${hours}:${minutes}`;
    }
    return "N/A";
  };

  const handleClickOpen = (employee) => {
    navigate(`/attendance/${employee.empId}`);
  };

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");
  
    worksheet.columns = [
      { header: "Employee ID", key: "employeeId", width: 15 },
      { header: "Employee Name", key: "firstName", width: 15 },
     
      { header: "Date", key: "date", width: 15 },
      { header: "Punch In Time", key: "punchInTime", width: 20 },
      { header: "Punch Out Time", key: "punchOutTime", width: 20 },
      { header: "Location", key: "location", width: 30 },
      { header: "Status", key: "status", width: 15 }, // The status column is the 6th column
    ];
  
    attendance.forEach((employee) => {
      worksheet.addRow({
        employeeId: employee.employeeId,
        firstName:  `${employee.firstName} ${employee.lastName}`,
       
        date: employee.date,
        punchInTime: formatTimeFromArray(employee.checkIn),
        punchOutTime: formatTimeFromArray(employee.punchOutTime),
        location: employee.checkinLocation,
        status: employee.date === todayDate ? "Present" : "Absent",
      });
    });
  
    worksheet.getRow(1).font = { bold: true, color: "green" };
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0000FF" },
    };
  
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        const statusCell = row.getCell(7); // Correcting to the 6th cell (status column)
        if (statusCell.value === "Present") {
          statusCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF00FF00" }, // Green color for Present
          };
        } else {
          statusCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF0000" }, // Red color for Absent
          };
        }
      }
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Employee_Attendance.xlsx");
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate attendance
  const paginatedAttendance = attendance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container elevation={2}>
      <Title variant="h5">Employees Attendance Management</Title>
      <SignedInSection>
        
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6"  ml={3} mt={3}>Employees Attendance:</Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7e31ce",
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },
            }}
            onClick={handleExport}
          >
             <ScreenShareOutlinedIcon sx={{ marginRight: 1 }} /> {/* Insert custom Excel icon */} 
            Export
          </Button>
        </Box>  

        <Box mt={3}>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableHeaderCell>
                      Thumbnail
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Employee Name
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Date
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Punch In Time
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Punch Out Time
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Location
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Status
                    </TableHeaderCell>
                    <TableHeaderCell>
                      Action
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader ? (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100px"
                        >
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedAttendance.map((employee) => (
                      <TableRow key={employee.id}>
                        <TableCell
                          className="tableCell"
                          style={{ border: "0.1rem solid black", textAlign:'center',
                            fontWeight:"bold" }}
                        >
                          <Avatar
                              alt={employee.firstName}
                              style={{marginLeft:'35%', marginBottom:'10px'}}
                              src={employee.checkInPic}
                            // onClick={attendanceNavigation}
                            
                            />
                            {employee.employeeId}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                        {employee.firstName} {employee.lastName}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {employee.date}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {formatTimeFromArray(employee.checkIn)}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {formatTimeFromArray(employee.checkOut)}
                        </TableCell>

                        {/* <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "17px",
                            textAlign: "center",
                            border: "0.1rem solid black",
                          }}
                        >
                          {truncateString(employee.checkinLocation, 50)}
                        </TableCell> */}
  <TableCell
  className="tableCell"
  style={{
    color: "black",
    fontSize: "17px",
    textAlign: "center",
    border: "0.1rem solid black",
    padding: 0,  // Remove padding from the cell
  }}
>
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%" // Ensure the box takes up the full width
  >
    {/* Check-in location */}
    <Typography variant="body2" style={{ fontSize: "17px", padding: "8px 0" }}>
      {truncateString(employee.checkinLocation, 50)}
    </Typography>

    {/* Divider Line */}
    {/* <hr style={{ width: "100%", border: "0.005rem solid black" }} /> */}
    <Box
      component="hr"
      sx={{
        width: "100%",
        border: "none",           // Remove default border
        height: "1px",            // Set line height to 1px for thinness
        backgroundColor: "black", // Set the line color to black
        margin: 0,                // Remove any margin
      }}
    />

    {/* Check-out location */}
    <Typography variant="body2" style={{fontSize: "17px", padding: "8px 0" }}>
      {truncateString1(employee.checkoutLocation, 50)}
    </Typography>
  </Box>
</TableCell>



                        <TableCell
                          className="tableCell"
                          style={{
                            color: "green",
                            fontSize: "17px",
                            textAlign: "center",
                            fontWeight: "bold",
                            border: "0.1rem solid black",
                          }}
                        >
                          {employee.date === todayDate ? "Present" : "Absent"}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ border: "0.1rem solid black" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#7e31ce",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#7e31ce" },
                                marginRight: "10px",
                              }}
                              onClick={() => handleClickOpen(employee)}
                            >
                              Attendance
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={attendance.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Box>
      </SignedInSection>
    </Container>
  );
};

export default EmployeeAttendance;