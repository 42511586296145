import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TablePagination,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import GroupIcon from '@mui/icons-material/Group';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Dashboard = () => {
  const [employeeCount, setEmployeeCount] = useState(0);
  const [presentsCount, setPresentsCount] = useState(0);
  const [attendance, setAttendance] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  useEffect(() => {
    const fetchEmployeeCount = async () => {
      try {
        const response = await fetch(
          ` https://login.hrbppayroll.com/hrbp/api/employee/count/by-organisation/1`
        );
        const data = await response.json();
        setEmployeeCount(data);
      } catch (error) {
        console.error("Failed to fetch employee count", error);
      }
    };

    fetchEmployeeCount();
  }, []);

  useEffect(() => {
    const fetchPresentsCount = async () => {
      try {
        const response = await fetch(
          ` https://login.hrbppayroll.com/hrbp/api/attendance/employees/count/${todayDate}`
        );
        const data = await response.json();
        setPresentsCount(data);
      } catch (error) {
        console.error("Failed to fetch presents count", error);
      }
    };

    fetchPresentsCount();
  }, [todayDate]);

  const totalAbsent = employeeCount - presentsCount;

  useEffect(() => {
    const fetchAllAttendance = async () => {
      try {
        setLoader(true);
        const response = await fetch(
          `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}`
        );
        const data = await response.json();

        const todayAttendace = data.filter(
          (employee) => employee.date === todayDate
        );
        const employeesWithImages = todayAttendace.map((employee) => ({
          ...employee,
          checkInPic: `data:image/jpeg;base64,${employee.checkInPic}`,
        }));

        // Sort employeesWithImages so newly added rows appear on top
        const sortedAttendance = employeesWithImages.reverse();

        setAttendance(sortedAttendance);
        console.log("I am 04-09-2024  Employee attendace :" + data.checkOut)
      } catch (error) {
        console.error("Failed to fetch attendance", error);
      } finally {
        setLoader(false);
      }
    };

    fetchAllAttendance();
  }, [todayDate]);

  // const truncateString = (str, num) => {
  //   if (typeof str !== "string") {
  //     return "N/A";
  //   }
  //   if (str.length <= num) {
  //     return str;
  //   }
  //   return str.slice(0, num) + "...";
  // };

  //for punch in location
  const truncateString = (str, num) => {
    if (typeof str !== "string") {
      return "Punch In Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };
  //for punch out location
  const truncateString1 = (str, num) => {
    if (typeof str !== "string") {
      return "Punch Out Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };


  const formatTimeFromArray = (timeArray) => {
    if (Array.isArray(timeArray) && timeArray.length >= 2) {
      const hours = String(timeArray[0]).padStart(2, "0");
      const minutes = String(timeArray[1]).padStart(2, "0");
      return `${hours}:${minutes}`;
    }
    return "N/A";
  };

  // const attendanceNavigation = () => {
  //   navigate("/employeeAttendance");
  // };

  const employeeClick = () => {
    navigate("/employee");
  };

  const handleAbsents = () => {
    navigate("/absentsList");
  };

  const handlePresents = () => {
    navigate("/employeeAttendance");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box p={3} marginTop={9}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} onClick={employeeClick}>
          <Card
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer'
            }}
          >
            <CardContent sx={{ textAlign: "center", width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: "row", sm: "row" }} // Keep row direction for all screens
              >
                <GroupIcon
                  sx={{
                    color: "#8000ff",
                    fontSize: { xs: 30, sm: 35 },
                    marginLeft: { xs: 2, sm: 2 }, // Margin for all screens
                    marginRight: { xs: 2 }, // Margin for mobile
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <Box textAlign="right">
                  <Typography variant="h6" style={{ marginRight: "20px" }} sx={{ fontSize: { xs: 'body2', sm: 'h6' } }}>
                    Employees
                  </Typography>
                  <Typography variant="h4" style={{ marginRight: "50px" }} sx={{ fontSize: { xs: 'h6', sm: 'h4' } }}>
                    {employeeCount}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer'
            }}
            onClick={handlePresents}
          >
            <CardContent sx={{ textAlign: "center", width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: "row", sm: "row" }} // Keep row direction for all screens
              >
                <EmojiPeopleIcon
                  sx={{
                    color: "#4caf50",
                    fontSize: { xs: 30, sm: 35 },
                    marginLeft: { xs: 2, sm: 2 }, // Margin for all screens
                    marginRight: { xs: 2 }, // Margin for mobile
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <Box textAlign="right">
                  <Typography variant="h6" style={{ marginRight: "20px" }} sx={{ fontSize: { xs: 'body2', sm: 'h6' } }}>
                    Presents
                  </Typography>
                  <Typography variant="h4" style={{ marginRight: "40px" }} sx={{ fontSize: { xs: 'h6', sm: 'h4' } }}>
                    {presentsCount}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer'
            }}
            onClick={handleAbsents}
          >
            <CardContent sx={{ textAlign: "center", width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: "row", sm: "row" }} // Keep row direction for all screens
              >
                <RemoveCircleOutlineIcon
                  sx={{
                    color: "#8000ff",
                    fontSize: { xs: 30, sm: 35 },
                    marginLeft: { xs: 2, sm: 2 }, // Margin for all screens
                    marginRight: { xs: 2 }, // Margin for mobile
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

                  }}
                />
                <Box textAlign="right">
                  <Typography variant="h6" style={{ marginRight: "20px" }} sx={{ fontSize: { xs: 'body2', sm: 'h6' } }}>
                    Absents
                  </Typography>
                  <Typography variant="h4" style={{ marginRight: "40px" }} sx={{ fontSize: { xs: 'h6', sm: 'h4' } }}>
                    {totalAbsent}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Card
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography sx={{ fontSize: 18, padding: 1, textAlign: "center" }}>
            <strong style={{ padding: 12 }}>Daily Employee Attendance</strong>
          </Typography>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Thumbnail
                    </TableCell>
                   
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Punch In Time
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Punch Out Time
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "0.1rem solid black",
                        // textTransform: "uppercase",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                {loader ? (<TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100px" // Adjust as needed
                    >
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>) : (
                  <TableBody>
                    {attendance
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((employee) => (
                        <TableRow key={employee.id}>
                          <TableCell
                            className="tableCell"
                            style={{
                              border: "0.1rem solid black",
                              textAlign:'center',
                              fontWeight:"bold"
                            }}
                          >
                            <Avatar
                              alt={employee.firstName}
                              style={{marginLeft:'35%', marginBottom:'10px'}}
                              src={employee.checkInPic}
                            // onClick={attendanceNavigation}
                            
                            />
                            {employee.employeeId}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.firstName} {employee.lastName}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.date}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {formatTimeFromArray(employee.checkIn)}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {formatTimeFromArray(employee.checkOut)}
                          </TableCell>

                          {/* <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "17px",
                              textAlign: "center",
                              border: "0.1rem solid black",
                            }}
                          >
                            {truncateString(employee.checkinLocation, 80)}
                          </TableCell> */}

<TableCell
  className="tableCell"
  style={{
    color: "black",
    fontSize: "17px",
    textAlign: "center",
    border: "0.1rem solid black",
    padding: 0,  // Remove padding from the cell
  }}
>
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%" // Ensure the box takes up the full width
  >
    {/* Check-in location */}
    <Typography variant="body2" style={{ fontSize: "17px", padding: "8px 0" }}>
      {truncateString(employee.checkinLocation, 50)}
    </Typography>

    {/* Divider Line */}
    {/* <hr style={{ width: "100%", border: "0.005rem solid black" }} /> */}
    <Box
      component="hr"
      sx={{
        width: "100%",
        border: "none",           // Remove default border
        height: "1px",            // Set line height to 1px for thinness
        backgroundColor: "black", // Set the line color to black
        margin: 0,                // Remove any margin
      }}
    />

    {/* Check-out location */}
    <Typography variant="body2" style={{fontSize: "17px", padding: "8px 0" }}>
      {truncateString1(employee.checkoutLocation, 50)}
    </Typography>
  </Box>
</TableCell>

                          <TableCell
                            className="tableCell"
                            style={{
                              color: employee.date === todayDate ? "green" : "red",
                              fontSize: "17px",
                              textAlign: "center",
                              fontWeight: "bold",
                              border: "0.1rem solid black",
                            }}
                          >
                            {employee.date === todayDate ? "Present" : "Absent"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>)}

              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={attendance.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      </Box>

    </Box>
  );
};

export default Dashboard;