import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  CircularProgress,
  TablePagination,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

function Employee() {
  const [employees, setEmployees] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Fetch employees
  const fetchEmployees = async () => {
    try {
      setLoader(true);
      const response = await fetch("https://login.hrbppayroll.com/hrbp/api/employee/getAll");
      const data = await response.json();
      const employeesWithImages = data.map((employee) => ({
        ...employee,
        profilePic: `data:image/jpeg;base64,${employee.profilePic}`,
      }));
      setEmployees(employeesWithImages);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoader(false);
    }
  };

  // Handle delete
  const handleDelete = async (employeeId) => {
    try {
      const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/employee/delete/${employeeId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        // Remove the employee from the state immutably
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee.employeeId !== employeeId)
        );
      } else {
        console.error("Error deleting employee:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleClickOpen = (employee) => {
    navigate(`/employeeDetails/${employee.empId}`);
  };

  const handleNavigateToOtherComponent = () => {
    navigate("/employeeRegForm");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedEmployees = employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      <Box mt={9} marginLeft={2} marginRight={2}>
        <Card
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Title variant="h5" sx={{ marginTop: 3 }}>Employees Management</Title>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h5" sx={{ fontSize: 20, marginTop: 5 }}>
                Employees Management:
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#7e31ce",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#13A9BD" },
                    fontWeight: "bold",
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                  onClick={handleNavigateToOtherComponent}
                >
                  Add New Employee
                </Button>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="tableHeader">
                  <TableRow>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>Thumbnail</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>Employee Id</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>Employee Name</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>Mobile</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>E-mail</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>Action</TableCell>
                    <TableCell style={{ color: "white", fontSize: "17px", textAlign: "center", fontWeight: "bold", border: "0.1rem solid black" }}>Delete</TableCell> {/* New header for delete column */}
                  </TableRow>
                </TableHead>

                {loader ? (
                  <TableRow>
                    <TableCell colSpan={7}> {/* Updated to match number of columns */}
                      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {paginatedEmployees.map((employee) => (
                      <TableRow key={employee.id}>
                        <TableCell className="tableCell" style={{ border: "0.1rem solid black" }}>
                          <Avatar alt={employee.firstName} style={{ marginLeft: 60 }} src={employee.profilePic} />
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "17px", textAlign: "center", border: "0.1rem solid black" }}>
                          {employee.employeeId}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "17px", textAlign: "center", border: "0.1rem solid black" }}>
                          {employee.firstName} {employee.lastName}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "17px", textAlign: "center", border: "0.1rem solid black" }}>
                          {employee.contactNumber1}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "15px", textAlign: "center", border: "0.1rem solid black" }}>
                          {employee.emailId}
                        </TableCell>
                        <TableCell className="tableCell" style={{ color: "black", fontSize: "12px", textAlign: "center", border: "0.1rem solid black" }}>
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#7e31ce", color: "#fff", "&:hover": { backgroundColor: "#13A9BD" }, textAlign: "center" }}
                            onClick={() => handleClickOpen(employee)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell className="tableCell" style={{ border: "0.1rem solid black", textAlign: "center" }}> {/* New cell for delete icon */}
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(employee.employeeId)} // Adjust this according to your ID field
                            style={{ color: "red" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={employees.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default Employee;
