import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import Swal from "sweetalert2";
import "./leave.css";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: "white",
  fontSize: "17px",
  textAlign: "center",
  fontWeight: "bold",
  border: "0.1rem solid black",
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  color: "black",
  fontSize: "15px",
  textAlign: "center",
  border: '0.1rem solid black'
}));

const ReasonTableCell = styled(TableCellStyled)({
  width: '30%', 
  textAlign: 'center',
});

const DefaultTableCell = styled(TableCellStyled)({
  width: '10%', 
  textAlign: 'center',
});

const ButtonCell = styled(TableCellStyled)({
  width: '10%',
  textAlign: 'center',
});

const EmployeeLeave = () => {
  const [attendanceList, setAttendanceList] = useState([]);
  const [status, setStatus] = useState({});
  const [disabledSaveButtons, setDisabledSaveButtons] = useState({});
  const [loadingSave, setLoadingSave] = useState({});
  const [loader, setLoader] = useState(true);

  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // const fetchLeaveRequestList = async () => {
  //   try {
  //     setLoader(true); 
  //     const response = await fetch(
  //       `https://login.hrbppayroll.com/hrbp/api/leave/allLeaveRequests`
  //     );
  //     const data = await response.json();
  //     setAttendanceList(data);

  //     const statusInit = data.reduce((acc, item) => {
  //       acc[item.lrId] = item.status || "Pending";
  //       return acc;
  //     }, {});
  //     setStatus(statusInit);

  //     const savedDisabledButtons =
  //       JSON.parse(localStorage.getItem("disabledSaveButtons")) || {};
  //     setDisabledSaveButtons(savedDisabledButtons);
  //   } catch (error) {
  //     console.error("Error fetching attendance list:", error);
  //   } finally {
  //     setLoader(false); 
  //   }
  // };

  //new added code
  const fetchLeaveRequestList = async () => {
    try {
      setLoader(true); 
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/leave/allLeaveRequests`
      );
      const data = await response.json();
  
      // Reverse the data array so new entries appear at the top
      setAttendanceList(data.reverse()); 
  
      const statusInit = data.reduce((acc, item) => {
        acc[item.lrId] = item.status || "Pending";
        return acc;
      }, {});
      setStatus(statusInit);
  
      const savedDisabledButtons =
        JSON.parse(localStorage.getItem("disabledSaveButtons")) || {};
      setDisabledSaveButtons(savedDisabledButtons);
    } catch (error) {
      console.error("Error fetching attendance list:", error);
    } finally {
      setLoader(false); 
    }
  };
  

  const handleStatusChange = (lrId, newStatus) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [lrId]: newStatus,
    }));
  };

  const handleSave = async (lrId) => {
    try {
      setLoadingSave((prev) => ({ ...prev, [lrId]: true })); 
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/leave/admin/employeeId/${lrId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: status[lrId] }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update leave request");
      }


      // Determine message based on status
    let swalMessage = '';
    switch (status[lrId]) {
      case 'Approved':
        swalMessage = 'Request Approved Successfully';
        break;
      case 'Rejected':
        swalMessage = 'Request Rejected Successfully';
        break;
      default:
        swalMessage = 'Request Updated successfully';
    }

    Swal.fire({
      icon: "success",
      title: "Request Updated",
      text: swalMessage,
    });
      // Swal.fire({
      //   icon: "success",
      //   title: "Status Updated",
      //   text: "Leave request status has been updated successfully",
      // });

      const updatedDisabledButtons = {
        ...disabledSaveButtons,
        [lrId]: true,
      };
      setDisabledSaveButtons(updatedDisabledButtons);
      localStorage.setItem(
        "disabledSaveButtons",
        JSON.stringify(updatedDisabledButtons)
      );
    } catch (error) {
      console.error("Error updating leave request:", error);
    } finally {
      setLoadingSave((prev) => ({ ...prev, [lrId]: false })); 
    }
  };

  useEffect(() => {
    fetchLeaveRequestList();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "orange";
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      default:
        return "black";
    }
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container elevation={2}>
      <Title variant="h5" sx={{marginBottom:'55px'}}>Employees Leave Management</Title>

      <TableContainer component={Paper}>
        <Table>
          <TableHead className="tableHeader">
            <TableRow>
              <TableHeaderCell>Employee Id</TableHeaderCell>
              <TableHeaderCell>Start Date</TableHeaderCell>
              <TableHeaderCell>End Date</TableHeaderCell>
              <TableHeaderCell>No Of Days</TableHeaderCell>
              <TableHeaderCell>Leave Type</TableHeaderCell>
              <TableHeaderCell>Reason</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <TableRow>
              <TableCell colSpan={8}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {attendanceList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((employee) => (
                  <TableRow key={employee.lrId}>
                    <DefaultTableCell>{employee.employeeId}</DefaultTableCell>
                    <DefaultTableCell>{employee.startDate.join("-")}</DefaultTableCell>
                    <DefaultTableCell>{employee.endDate.join("-")}</DefaultTableCell>
                    <DefaultTableCell>{employee.noOfDays}</DefaultTableCell>
                    <DefaultTableCell>{employee.leaveType}</DefaultTableCell>
                    <ReasonTableCell>{employee.reason}</ReasonTableCell>
                    <DefaultTableCell>
                      <select
                        id={`select-${employee.lrId}`}
                        value={status[employee.lrId] || ""}
                        onChange={(e) => handleStatusChange(employee.lrId, e.target.value)}
                        style={{
                          width: "90%",
                          height: "35px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          backgroundColor: "#f9f9f9",
                          fontSize: "14px",
                          color: getStatusColor(status[employee.lrId]),
                        }}
                      >
                        <option value="" disabled>
                          Select status
                        </option>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    </DefaultTableCell>
                    <ButtonCell>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#7e31ce",
                          color: "#fff",
                          "&:hover": { backgroundColor: "#7e31ce" },
                        }}
                        onClick={() => handleSave(employee.lrId)}
                      >
                        {loadingSave[employee.lrId] ? (
                          <CircularProgress size={24} sx={{ color: "#fff" }} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </ButtonCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        {/* Pagination Component */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={attendanceList.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
};

export default EmployeeLeave;
